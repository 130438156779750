import { Experiment, ExperimentClient } from "@amplitude/experiment-js-client";
import { IFeatureManager } from "..";

export enum ExperimentServerZone {
  US = "us",
  EU = "eu",
}

export enum FeatureFlag {
  On = "on",
  Off = "off",
}

export class AmplitudeFlagAndExperimentManager implements IFeatureManager {
  private _experimentClient: ExperimentClient;

  public constructor(
    private readonly deploymentKey: string,
    private readonly experimentServerZone?: ExperimentServerZone,
    private readonly enableDebugger: boolean = false
  ) {
    if (!deploymentKey) {
      throw new Error("deploymentKey");
    }

    this._experimentClient = Experiment.initialize(this.deploymentKey, {
      debug: this.enableDebugger,
      serverZone: this.experimentServerZone ?? ExperimentServerZone.US,
    });
  }

  public start(): Promise<void> {
    return this._experimentClient.start();
  }

  public isFeatureFlagEnabled(name: string, expectedValue: any): boolean {
    const flagVariant = this._experimentClient.variant(name);

    return flagVariant.value === expectedValue;
  }
}
