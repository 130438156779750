export default {
  appointmentsSection: {
    loadingInfo: "Loading your info",
    upcomingAppointments: "Your upcoming appointments",
    pastAppointments: "Your past appointments",
    noAppointment: "You have no appointment yet",
    clickHereToSchedule: "Click here to get a screening.",
    appointmentCard: {
      cancelButtonText: "Cancel",
      editButtonText: "Modify",
      rescheduleButton: "Reschedule",
      retakeQuestionnaire: "Fill out the questionnaire again",
      cancellationIndicator: "Cancelled",
      staffCancellationIndicator: "Must be rescheduled",
      consultButtonText: "Consult",
      dateIndicator: (date) => `${date} screening appointment `,
      medicalTestsIndicator: "Tests required",
      resultsIndicator: "Your results",
      missedMessage:
        "You’ve missed your appointment, but you still have time to reschedule it.",
      resultsNotReadyLine1: "We’re still waiting on your results;",
      resultsNotReadyLine2: "it shouldn’t take much longer.",
      missedAppointmentMessage:
        "It looks like you’ve missed your appointment and your medical questionnaire has expired. To schedule another appointment, fill out the online medical questionnaire once again.",
    },
    phoneAppointmentCard: {
      chooseButtonText: "Choose",
      editButtonText: "Change",
      consultButtonText: "Consult",
      rejectButtonText: "Decline",
      title: "Phone appointment",
      independantTitle: "Phone appointment",
      prepTitle: "PrEP phone appointment",
      rejectedIndicator: "Declined",
      canceledIndicator: "Canceled",
      dateIndicator: (date) => `${date} phone appointment`,
      prepDateIndicator: (date) => `PrEP phone appointment of ${date}`,
      independantDateIndicator: (date) => `${date} phone appointment`,
      descriptionLine1: (assAppDate) =>
        `Phone appointment concerning your ${assAppDate} screening appointment.`,
      descriptionLine2:
        "One of our staff members will call you at the time of your appointment.",
      prepDescription:
        "The call with the participating pharmacy provides all the information needed to determine whether or not PrEP can be initiated, and to ensure the necessary follow-up.",
      prepDescription2:
        "If the phone appointment is not completed or if you are not eligible, the creatinine sample will be cancelled.",
      missedMessage:
        "It looks like you've missed your phone appointment, but you can still reschedule it.",
      chooseDate:
        "You’ve been invited to a phone appointment. Choose a time and date.",
    },
    cancellationModal: {
      cancellationButtonText: "Cancel appointment",
      confirmButtonText: "Confirm",
      exitButtonText: "Cancel",
      header: (date) => `Modify your ${date} appointment`,
      keepButtonText: "Keep appointment",
      payButton: "Pay",
      rescheduleButtonText: "Choose another date*",
      feesMightApply: "*Certain fees may apply.",
      faqUrl: "https://www.prelib.com/en?faq=14#faqs",
      appointmentCanceled: "Your appointment was cancelled.",
      phoneAppointmentRejected: "Your appointment was declined.",
      cancelAppointmentNotAllowed:
        "It is not allowed to cancel a missed appointment or an appointment taking place in less than 4 hours.",
    },
    cancellationConfirmModal: {
      title: "Cancelling your appointment will be definitive.",
      phoneTitle: "Cancelling your phone appointment will be definitive.",
      prepEligiblity:
        "If you cancel your call with the pharmacist, PrEP-related services cannot be provided. You will still be able to go to your STI screening appointment.",
      content:
        "If you cancel your appointment, fees related to the online medical evaluation cannot be reimbursed.",
    },
    creditInfoModal: {
      cardBillingAddressLabel: "Billing address",
      cardExpirationLabel: "Expiration date",
      cardHolderNameLabel: "Cardholder name",
      content:
        "Please confirm your credit card information for the payment of\u00A0",
      modifyButton: "Modify",
      link: "the $35 administrative fee (taxes not included).",
      faqUrl: "https://www.prelib.com/en?faq=14#faqs",
      payButton: "Pay",
    },
    gatherCreditInfoModal: {
      content:
        "Please enter your credit card information for the payment of \u00A0",
      link: " the $35 administrative fee (taxes not included).",
    },
    rescheduleAppointmentModal: {
      title: "Select a date",
      prepSubtitle:
        "The call with the pharmacist must precede your appointment for the",
      confirmButtonText: "Select",
      exitButtonText: "Cancel",
    },
    feedbackModal: {
      title: "Satisfaction Survey",
      confirmButtonText: "Submit",
      letUsKnowHowItWent: "Tell us about your experience at Prelib.",
      satisfactionLevel:
        "What is your level of satisfaction with the Prelib service?",
      recommendationProbability:
        "How likely are you to recommend Prelib to your friends or colleagues?",
      recommendationJustification:
        "In your own words, why did you provide this rating?",
      textAreaPlaceholder: "Please write a note ...",
      phoneCommunicationAgreement:
        "Would you be comfortable if a member of our team contacted you by phone in order to improve our services?",
      yes: "Yes",
      no: "No",
    },
  },
  dateSection: {
    titleWithPrep: "Make an appointment",
    prepSubtitle: "PrEP Solution",
    prepDescription:
      "The PrEP Solution program makes it easy for you to get PrEP. To benefit from this service, a phone appointment with a participating pharmacist is required to check your eligibility. If you don't want to take PrEP through the PrEP Solution program, you can still book an appointment for an STI screening without any problems, and then get PrEP through the medical clinic and pharmacy of your choice.",
    prepConsent:
      "*I agree that a pharmacy participating in the PrEP Solution program will receive my contact information and the information relevant to my prescription and will contact me as available.",
    makePrepAppointmentButtonText: "Make an appointment",
    abortPrepCheckboxText: "No, I don't want PrEP",
    prepNotDecidedError:
      "Please indicate if you are interested in following PrEP with us",
    screeningSectionTitle: "Screening",
    screeningSectionDescription:
      "It is important that the screening appointment takes place after the telephone appointment with a participating pharmacist.",
    clinicsAreLoading: "List of screening clinics currently being uploaded",
    pharmacistCalendarIsLoading: "Pharmacist calendar is loading",
    header: "Select your clinic and time slot.",
    submitButtonText: "Submit",
  },
  dateSelectorComponent: {
    choseDateLabel: "Select a date",
    choseHourLabel: "Time",
    noAvailability: "No availability",
    noDateAvailability:
      "No availability at the moment. Please try again tomorrow.",
  },
  unsubscribeConfirmation: {
    goToHomeButton: "View your profile",
    header: "Unsubscribing successful",
    invalidTokenError: "Invalid or expired",
    successMessage:
      "You have successfully unsubscribed from all automated email communications. You can always resubscribe by clicking on “Subscribe to follow-up emails” in the “Personal Information” tab of your patient portal.",
  },
  satisfactionQuestionnaire: {
    title: "Satisfaction questionnaire",
    letUsKnowHowItWent: "Let us know how your experience at Prelib was",
    satisfactionLevel:
      "What is your level of satisfaction with the Prelib service?",
    recommendationProbability:
      "How likely are you to recommend Prelib to your friends or colleagues?",
    recommendationJustification:
      "In your own words, why did you provide this rating?",
    phoneCommunicationAgreement:
      "In order to improve our services, would you accept that a member of our team contact you by phone?",
    yes: "Yes",
    no: "No",
    submit: "Submit",
  },
  expiredSection: {
    header: "Your questionnaire has expired.",
    restart: "Fill out the questionnaire once more",
    message:
      "Your questionnaire has expired.\n\nThe 45-day deadline for making an appointment has passed. Your medical questionnaire is therefore no longer valid, and a complete re-evaluation is necessary.\n\nIf you have any questions, contact us at info@prelib.com, and a member of our team will get back to you shortly.",
  },
  emailConfirmation: {
    goToHomeButton: "View your profile",
    header: "Email confirmation",
    invalidTokenError: "Invalid or expired",
    successMessage: "Your email address has been confirmed!",
  },
  footer: {
    cityAddress: "Montreal, Quebec  H2L 4L8",
    civicAddress: "1485 St-Hubert St.",
    disclaimer: "@2021 Prelib All rights reserved.",
    emailLabel: "E",
    weekendLabel: "S",
    beginningOfWeekLabel: "M - W",
    endOfWeekLabel: "T - F",
    schedule: [
      {
        title: "Mon - Fri: ",
        content: "7 a.m. to 8 p.m.",
      },
      {
        title: "Sat: ",
        content: "8:30 a.m. to 3 p.m.",
      },
      {
        title: "Sun: ",
        content: "Closed",
      },
    ],
    followUs: "Follow us",
    facebookAlt: "Facebook Logo",
    instagramAlt: "Instagram Logo",
    youtubeAlt: "Youtube Logo",
    prelibAlt: "Prelib Logo",
  },
  forgottenPassword: {
    header: "Password forgotten",
    instruction:
      "Enter your email address below and we’ll send you an email to reset your password.",
    rhetoricalQuestion: "Forgot your password\u00A0?",
    emailLabel: "Email address",
    invalidEmailError: "Invalid email address",
    sendButtonText: "Submit",
    successMessage: "Thank you!",
  },
  homePage: {
    topMenu: {
      completeScreeningButtonText: "Confirm the appointment",
      disconnectButtonText: "Sign out",
      greetingsText: "Hi",
      makeScreeningButtonText: "Get tested",
      updateProfile: "Update my profile",
      myDirectoryDecorator: "MY ACCOUNT",
    },
    logo: {
      myDirectoryDecorator: "MY ACCOUNT",
    },
    subheaderTab: {
      screeningTitle: "Carry out a screening",
      myAppointmentTitle: "My appointements",
      myPrepTitle: "My PrEP space",
    },
    tooltipTab: {
      screening: "You already have an appointment.",
      phoneAppointment:
        "You must book and complete your follow-up phone appointment before you can carry out a new screening.",
    },
    payMissedAppointmentFeesPopup: {
      title:
        "Because you missed your last appointment, you must pay 35$ before you can book a new appointment.",
    },
  },
  infoConfirmationSection: {
    birthDateLabel: "Select your birth date!",
    birthDateConfirmation: "Confirm date of birth",
    header: "Personal information",
    completeButtonText: "Fill out",
    confirmInfo: "Confirm information",
  },
  login: {
    alreadyHasAccountLink: "You already have an account.",
    createAccountLink: "Create an account",
    emailConfirmationMessage: "You must now confirm your email address.",
    emailLabel: "Email address",
    firstNameLabel: "First name",
    firstNameWarning: "As shown on your RAMQ health insurance card.",
    forgottenPasswordLink: "Forgot your password?",
    invalidEmailError: "Invalid email address",
    invalidPasswordError: "Invalid password",
    invalidPhoneNumber: "Phone number not valid",
    loginHeader: "My account",
    lastNameLabel: "Last name",
    lastNameWarning: "As appears on RAMQ health insurance card",
    loginButtonText: "Sign in",
    consentMessage: `I have read and accept Prelib's <a href="https://prelib.com/terms" rel="noopener noreferrer" target="_blank">general terms of use </a> as well as <a href="https://prelib.com/confidentiality" rel="noopener noreferrer" target="_blank"> its privacy policy </a> .`,
    passwordErrors: {
      maxLength: "Your password must contain no more than 128 characters.",
      minLength: "Your password must contain at least 10 characters.",
      needLowercase:
        "Your password must contain at least one lower-case letter.",
      needNumber: "Your password must contain at least one number.",
      needSpecialSymbol:
        "Your password must contain at least one non-alphanumeric symbol.",
      needUppercase: "Your password must contain at least one capital letter.",
    },
    passwordWarning:
      "Your password is strictly confidential and must not be disclosed to third parties.",
    passwordLabel: "Password",
    tfaLabel: "2-Step Verification",
    tfaContext:
      "For the security of your data, your account will now be protected by 2-step authentication. When logging in, you will have to confirm your identity using a 6-digit code. How do you want to receive this code?</b>",
    byEmail: "By email",
    bySms: "By SMS",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "(xxx) xxx-xxxx",
    phoneNumberError: "Invalid phone number",
    requiredConsentError: "Consent required",
    requiredFieldError: "Required field",
    signUpButtonText: "I am registering",
    signUpHeader: "Create my Prelib file",
    resendConfirmationEmail: "Resend the confirmation email to ",
    confirmationResent: "A confirmation email was just sent to ",
    accountCreated: "Your file was created",
    resend: "Resend confirmation email",
    formErrors: "There are errors in the form",
    hotmailWarning:
      "If you use @hotmail, Prelib emails may end up in your spam folder. We recommend that you add Prelib to your contact list so you don't miss any emails.",
    warning: "Warning",
  },
  notification: {
    bookedAppointment: "A confirmation email was just sent to you.",
    canceledAppointment: "Appointment cancelled!",
    satisfactionQuestionnaireSent: "Satisfaction survey sent.",
    rescheduledAppointment: "Appointment rescheduled!",
    scheduledPhoneAppointment: "Phone appointment scheduled",
    appointmentUpdated: "Your appointment was updated",
    retakeRejectedAppointment: "Appointment rescheduling declined",
    unexpectedError: "An error has occurred!",
  },
  paymentSection: {
    cancelButton: "Cancel",
    cardExpirationDate: "Expiration date (MM/YY)",
    cardHolderAddress: "Address",
    cardHolderCity: "City",
    cardHolderName: "Cardholder name",
    cardHolderZipCode: "Postal code",
    cardNumber: "Card number",
    cardSecurityCode: "Security code",
    chargeExplanation:
      "By completing this transaction, you’ll gain access to personalized recommendations and will be able to book your appointment.",
    header: "Payment",
    noRefundWarning:
      "Your card will be charged with a $\u00A040.50 non-refundable electronic medical evaluation fee.",
    payButtonText: "Pay",
    privacyMessage:
      "Your data is protected by the SSL standard Internet security protocol. The data is encrypted and exchanged with an HTTPS server. We respect your confidentiality. We provide your name, address and email address only to the merchant.",
    applyCouponLabel: "Apply promo code",
    applyCouponButton: "Apply",
    couponDiscountLabel: "Discount",
    delayDisclaimer:
      "* It may take some time to process the payment. Leave the page open until confirmation.",
  },
  profileSection: {
    addressLabel: "Address",
    addressPlaceHolder: "Address",
    birthDateLabel: "Date of birth",
    bornGender: "Gender assigned at birth",
    cancelTextButton: "Cancel",
    cardBillingAddressLabel: "Billing address",
    cardExpirationLabel: "Expiration date",
    cardHolderNameLabel: "Cardholder name",
    cityPlaceHolder: "City",
    emailRemindersDisabled: "Unsubscribe from follow-up emails",
    false: "No",
    firstNameLabel: "First name",
    generalHealthHeader: "General Health",
    jobLabel: "Occupation",
    lastNameLabel: "Last Name",
    modifyLabel: "Change",
    modifyTextButton: "Change",
    paymentMethodHeader: "Payment method",
    personalInformationHeader: "Personal information",
    ramqNumberExpirationLabel: "RAMQ expiration",
    ramqNumberLabel: "RAMQ number",
    ramqNumberError: "Invalid RAMQ number",
    phoneNumberLabel: "Phone number",
    phoneNumberError: "Invalid phone number",
    selfDefinedGender: "Gender",
    sexualHealthHeader: "Sexual health",
    choose: "Select",
    true: "Yes",
    zipCodePlaceHolder: "Postal code",
    followUpEmails: "Follow-up emails",
    unsubscribe: "Unsubscribe",
    subscribe: "Subscribe",
    confirmPhoneNumber: "Confirm your phone number",
    tfaPreference: "2-Step authentication preferences",
    phoneNumberMustBeConfirmed:
      "You must confirm your phone number in order to use text messaging as your 2-Step authentication method.",
    cancel: "Cancel",
    save: "Save",
    password: "Password",
    requestPassword: "Send me a password reset link",
    sending: "Sending...",
    emailSent: "Email sent",
  },
  phoneConfirmationModal: {
    title: "Phone number confirmation",
    sentTo: (phone) => `A confirmation code has been sent to ${phone} via SMS.`,
    instructions:
      "Enter your confirmation code in the field below to confirm your phone number.",
    notReceived: "Haven’t received your code yet?",
    resend: "Send another code",
    wrongPhone: "Wrong phone number?",
    changePhone: "Click here to update your profile",
    placeholder: "6-digit code",
    submit: "Confirm",
    invalid: "Invalid code",
    successMessage: "Your phone number is confirmed",
  },
  questionnaireComponent: {
    blockText: "or press <strong>Enter</strong>",
    incompleteQuestionnaireError: "Invalid form",
    noRamqButton: "I am not registered to the RAMQ",
    completeBtn: "Fill out",
    okBtn: "OK",
    yearLbl: "Year",
    phoneNumber: "Phone number",
    requiredFieldError: "Required field",
    invalidFormError: "There is/are invalid field(s) in the form",
    selectRequiredFieldError: "You must select an answer",
    ramqNumberInvalidError: "Invalid RAMQ number",
    phoneInvalidError: "Invalid phone number",
    underFifteenError: "Prelib aims its services at everyone aged 15 and older",
  },
  questionnaireSection: {
    completeButtonText: "Fill out",
    header: "Form regarding your general health",
    takeQuestionnaire: "Fill out the questionnaire",
    modal: {
      consentButtonText: "I hereby consent",
      consentHeader: "I agree.",
      consentFirstP:
        "By clicking on the “Accept” button hereafter, I hereby consent to Prelib inc. (“Prelib”) and its physicians conducting one or more remote consultations as part of an asymptomatic screening process for sexual health via an electronic standardized questionnaire. Remote consultation is available only for people physically located in Quebec. By clicking the “Accept” button, I certify that I am currently in Quebec.",
      consentSecondP:
        "I hereby understand and acknowledge (i) that Prelib has at its disposal all possible technological means to reduce the risks of breach of confidentiality and/or loss of image definition during electronic transmission of this confidential information, (ii) that there are risks and limits related to the use of telemedicine and this website, including any technological limits associated with remote consultation and the risks inherent in data processing, (iii) that I may not use this Web site for a medical emergency, and (iv) that Prelib may collect, use and share my personal (and medical) information in accordance with the Confidentiality Policy published on this website.",
      consentThirdP:
        "I hereby authorize the opening of an electronic patient file, in which the information transmitted during this or these consultation(s) will be deposited while ensuring the confidentiality of this personal information.",
      consentFourthP:
        "I agree to retain the services of Prelib and I consent to the remote medical consultation provided via Prelib's web site.",
      continueButtonText: "Continue",
      recommendationHeader: "Recommendations",
      recommendationSubHeader:
        "Prelib strongly recommends you consult a physician in the following situations",
      recommendationFirstP: "Presence of STBBI symptoms",
      recommendationSecondP:
        "Potential exposure during at-risk sexual relations over the past 72 hours to evaluate whether post-exposure prophylaxis (PEP) treatment is suitable for you.",
      recommendationThirdP: "Pregnancy",
      refuseButtonText: "I decline.",
    },
  },
  datetimePicker: {
    chooseAnHour: "Choose an hour",
    timePickerPlaceHolder: "Hour",
  },
  recommendationsSection: {
    restartQuestionnaire: "Fill out the medical questionnaire once more.",
    choseDateButtonText: "Select a date",
    updateTestSelection: "Update my test selection",
    analScreening: "Anal screening",
    bloodScreening: "Blood screening",
    customRecommendationsPresentation:
      "Here are some recommendations based on the answers you provided:",
    condomRecommendation:
      "The use of a condom during any sexual relation significantly reduces your risks of contracting an STI.",
    drugSharingRecommendation:
      "If you consume drugs, never share or reuse disposable injection or inhalation instruments.",
    extraCharges:
      "*Note that fees related to your tests will be charged on site.",
    header: "Recommendations",
    importantNewEvaluation:
      "You have 45 days to book an appointment, after which a new medical evaluation will be required.",
    importantCancelTest:
      "Payment for transportation fees ($5 - $15) or Dynacare private laboratory fees, if applicable, will be required at the time of your appointment.",
    importantDisabled:
      "If you have impaired mobility, email us prior to your appointment, at info@prelib.com, for the address where you can gain access to the center.",
    importantFee:
      "At the time of your appointment, you will have the possibility to remove any unwanted laboratory tests.",
    importantHeader: "IMPORTANT",
    importantSymptoms:
      "If you notice any symptoms prior to your appointment, we recommend you seek treatment with a physician.",
    medicalTestsPresentation:
      "Based on the answers you provided, we recommend you complete the tests listed below. You can always modify the selection.",
    minimalTestsCountError: "You must select at least one test.",
    oralScreening: "Oral screening",
    ppeLink: "post-exposure prophylaxis (PEP).",
    ppeRecommendation:
      "Because you had at-risk sexual relations in the past 72 hours, Prelib strongly recommends that you consult a clinic to evaluate whether you should be prescribed",
    prepLink: "pre-exposure prophylaxis (PrEP).",
    prepRecommendation:
      "Since your sexual practices pose a potentially high risk of contracting HIV, visit a specialized clinic and ask about",
    prepRecommendation2: "",
    retakeRecommendationExplanation:
      "Repeated screening tests for the infections listed above will allow to cover the window period, which is the maximum period before the infection can be detected in someone’s blood. ",
    retakeRecommendationHeader:
      "To ensure accurate results, you may be required to redo certain tests, depending on the time elapsed between potential exposure and screening.",
    retakeRecommendationHIV: "HIV: control to be carried out starting",
    retakeRecommendationHepatiteC:
      "Hepatitis C: control to be carried out starting",
    retakeRecommendationSyphilis:
      "Syphilis: control to be carried out starting",
    urinaryScreening: "Urinary screening",
    vaxRecommendation:
      "Your vaccination profile appears to be out of date. Ask your family doctor or a specialized clinic for more information. It’s simple, and often free of charge!",
    combinedOralNaatTest: "Chlamydia/Gonorrhea throat (NAAT)",
    itssSlug: "stbbi",
    appointmentUpdated: "Your appointment has been updated",
  },
  sectionSelection: {
    appointmentsSection: {
      canceledAppointments: "Your cancelled appointments",
      header: "Your appointments",
      pastAppointments: "Your previous appointments",
      upcomingAppointments: "Your upcoming appointments",
      missedAppointments: "Your missed appointments",
    },
    makeScreeningSection: {
      date: "Appointment",
      header: "Get tested",
      information: "Information",
      medicalEvaluation: "Medical evaluation",
      payment: "Payment",
      recommendations: "Recommendations",
      expired: "Expired questionnaire",
      appointmentCreated: "Your appointment has been scheduled",
    },
    myProfileSection: {
      generalHealth: "General health",
      header: "My profile",
      personalInformation: "Personal information",
      sexualHealth: "Sexual health",
    },
    myResultsSection: {
      header: "Your results",
      tooltipMessage: "You have no results.",
    },
    welcomeTextBasic: "Welcome to Prelib",
    welcomeTextInfo: "Your last screening was completed on",
    welcomeTextReminder: "Your next appointment is scheduled for",
  },
  resultsSection: {
    invalidResultLabel: "Invalid",
    undisclosedResultLabel: "Pending",
    toRetakeResultLabel: "To be taken again",
    negativeResultLabel: "Negative",
    positiveResultLabel: "Positive",
    notDoneResultLabel: "Not completed",
    cancelled: "Cancelled",
    recommendations: "Recommendations:",
    recommendationsWhenCancel:
      "Even if your appointment has been cancelled, the following recommendations still stand:",
    recommendationsWhenNoResult:
      "Until then, keep the following recommendations in mind:",
    recommendationsWhenNotDone:
      "Even if the appointment is not done yet, those recommendations are still valid:",
    resultsNotReadyLine1:
      "We’re still waiting on your results; it shouldn’t take much longer!",
    resultsNotReadyLine2:
      "You’ll receive a message when your results are available.",
    giveUsYourFeedback: "Got some feedback for us?",
    thankYouForFeedback: "Thank you for your feedback!",
    rowHeader: (date) => `${date} appointment`,
    pendingInfo: {
      header: "Results pending:",
      explanation:
        "A sample is “pending” when results have yet to come back from laboratory analysis. This turnaround time is normal, and the results will be sent to you before long (expect an additional 7 working days).",
    },
    retakeInfo: {
      row1_1: "One of your samples could not be interpreted by the lab.",
      row1_2:
        "This may be a laboratory error or an improper sample. Please don't be alarmed.",
      row2_1:
        "Prelib offers you to retake this sample free of charge provided you make an appointment at our center within the next 45 days.",
      retakeButton: "Reschedule appointment",
      rejectRetakeButton: "You do not want to reschedule an appointment.",
    },
    schedulePhoneAppointment: {
      title:
        "You have been invited to schedule a phone appointment. Please pick a date that suits you",
      scheduleButton: "Schedule appointment",
    },
  },
  resetPassword: {
    changeButtonText: "Change",
    confirmationLabel: "Confirm password",
    header: "New password",
    invalidPasswordError: "Invalid password",
    passwordDontMatchError: "Passwords don't match.",
    invalidTokenError: "The token is invalid!",
    passwordLabel: "Password",
    successMessage: "Your password has been successfully reset!",
  },
  signUp: {
    accountCreated: "Your file was created",
    alreadyHasAccount: "Already have an account?",
    byEmail: "By email",
    bySms: "By SMS",
    confirmationResent: "A confirmation email was just sent to ",
    consentMessage: `I agree to the <a href="https://prelib.com/terms" rel="noopener noreferrer" target="_blank">terms of use</a>.`,
    emailConfirmationMessage: "You must now confirm your email address.",
    emailLabel: "Email",
    firstNameLabel: "First name",
    formErrors: "There are errors in the form",
    clickHere: "Click here to",
    header: "Create Your Prelib Account",
    howDidYouHearAboutUs: {
      title: "How did you hear about Prelib?",
      options: [
        "Search Engine (Google, Bing, etc.)",
        "Social Media (Facebook, Instagram, Twitter, etc.)",
        "Friend or Family Recommendation",
        "Online Ads",
        "News Article",
      ],
      other: "Other",
      pleaseSpecify: "Please specify",
      selectOption: "Select an option...",
    },
    hotmailWarning:
      "If you use @hotmail, Prelib emails may end up in your spam folder. We recommend that you add Prelib to your contact list so you don't miss any emails.",
    invalidEmailError: "Invalid email address",
    invalidPasswordError: "Invalid password",
    login: "Login",
    loginToAccount: "login",
    lastNameLabel: "Last name",
    nameWarning: "As shown on your RAMQ card",
    optionalField: "*optional",
    passwordLabel: "Password",
    passwordWarning:
      "Your password is strictly confidential and must not be disclosed to third parties.",
    phoneNumberError: "Invalid phone number",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "(xxx) xxx-xxxx",
    requiredFieldError: "Required field",
    resend: "Resend confirmation email",
    signUpButtonText: "Register",
    tfaLabel: "Enable 2-Step Verification",
    tfaContext:
      "Which method do you prefer to receive your verification code when you login to Prelib?",
    warning: "Warning",
  },
  tfaInput: {
    title: "2-Step Authentication",
    sentTo: (sentBy, sentTo) =>
      `Your account is protected by 2-Step authentication. A 6-digit authentication code has been sent by ${sentBy} to ${sentTo}.`,
    resentCode: (sentBy, sentTo) =>
      `A 6-digit authentication code has been resent by ${sentBy} to ${sentTo}.`,
    checkboxLabel:
      "Don't ask me for the code again for 30 days when I use this device.",
    fieldLabel: "Enter 6-digit code :",
    codeError: "Invalid code",
    verifyButton: "Authenticate",
    resendCode: "Resend a 6-digit authentication code",
  },
  tfaSettings: {
    title: "2 step-verification",
    subtitle:
      "For the security of your data, your account will now be protected by 2-step authentication. When logging in, you will have to confirm your identity using a code. How do you want to receive this code?",
    confirmButton: "Continue",
    bySms: "By SMS",
    byEmail: "By email",
    phoneConfirmationButton: "Confirm phone number",
    phoneNumberLabel: "Phone number",
    phoneNumberPlaceholder: "Phone number format: (xxx) xxx-xxxx",
    phoneNumberError: "Invalid phone number",
  },
  select: {
    startTyping: "Start typing",
    keepTyping: "Keep typing",
    noResults: "No results",
  },
  months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  humanizeDateFormatter: {
    unknownDate: "No time slot are available at the moment",
    nextAvailableTime: "The next available time slot is",
    date: (day, shortMonth, hour) => `${shortMonth} ${day} at ${hour}`,
  },
  addressForm: {
    addressLine: "Postal address",
    city: "City",
    province: "Province",
    country: "Country",
    postalCode: "Postal Code",
  },
  prepSection: {
    prepStatus: {
      title: "PrEP status",
      inactive: {
        title: "No PrEP status",
        description:
          "Thanks to the PrEP Solution, you can get PrEP with ease. This service is only available if the questionnaire indicates that you are eligible for PrEP. If you think you have been omitted by mistake, do not hesitate to contact our team at info@prelib.com.",
        btn: "Make an appointment*",
        disclaimer:
          "*I consent to a pharmacy participating in the Solution PrEP program receiving my contact details and information relevant to my prescription and contacting me according to the availability chosen.",
      },
      active: {
        title: "PrEP Status: Active",
        description:
          "When PrEP status is active, this means you are taking pre-exposure prophylaxis (PrEP) via PrEP Solution.",
        btn: "Stop PrEP monitoring",
        history: "My History",
        today: "Today",
        by: "by",
        expiration: "Expiration date of",
        status: {
          ELIGIBLE: "PrEP status: Active",
          PENDING: "PrEP status: Pending",
          INELIGIBLE: "PrEP status: Inactive",
          REVOKED: "PrEP status: Inactive",
          EXPIRED: "PrEP status: Inactive",
        },
      },
    },
    prepDelivery: {
      title: "Delivery Profile",
      disabledPopover: "PrEP status required",
      inactive: {
        title: "Delivery: inactive",
        description:
          "Shipping is currently inactive for your account. To activate and select your delivery profile, choose the delivery option that suits you. Your request will then be examined and confirmed by the participating pharmacy.",
      },
      active: {
        title: {
          "1_MONTH": "My current plan: Scheduled Delivery (1 month)",
          "3_MONTHS": "My current plan: Scheduled Delivery (3 months)",
          ON_DEMAND: "My current plan: On-Demand Delivery",
          ON_PAUSE: "Delivery on Pause",
        },
        nextDeliveryTitle: "My next delivery",
        pastDeliveriesTitle: "Past deliveries",
        futureDeliveriesTitle: "Future deliveries",
        actionTooltip: {
          activateDeliveryToEdit: "Activate delivery to edit",
          editDate: "Edit date",
          editDestination: "Edit destination",
          activate: "Activate",
          cancel: "Cancel",
        },
        tabHeader: {
          deliveryDate: "Delivery Date",
          status: "Status",
          address: "Address",
          actions: "Actions",
        },
        editDate: {
          title: "Choose your new delivery date",
          description: "Select a new delivery date from the calendar below.",
          continue: "Continue",
          reject: "Cancel",
        },
        editAddress: {
          title: "Choose your delivery method",
          destinationSelect: {
            atHome: "At home",
            postPickUp: "Post pick-up",
            pharmacyPickUp: "Pharmacy pick-up",
            prelibPickUp: "Prelib pick-up",
          },
          continue: "Continue",
          reject: "Cancel",
          adresse: "Address",
          city: "City",
          zipCode: "Zip code",
          province: "Province",
          applyToAll:
            "I would like to apply this change to all future deliveries",
          pharmacyPickUp: "Delivery to the Pharmacie du Village",
          prelibPickUp: "Pick-up at the Prelib center: ",
          on: "on",
          atNextAppointment: " at your next screening appointment.",
          validationMessages: {
            address: "Please indicate your address!",
            city: "Please indicate your city!",
            zipCode: "Please indicate your zip code!",
          },
        },
        cancelDelivery: {
          title: "Cancellation of delivery for the current month",
          description:
            "If you refuse a scheduled delivery, you can always reactivate it before your next one. Once a delivery has been confirmed, you can cancel it up to 72 hours before the scheduled date.",
          cancel: "Cancel my delivery",
        },
        deliverySummary: {
          quantityLabel: "Quantity: ",
          destinationLabel: "Delivery destination: ",
          addressLabel: "Delivery address: ",
          justificationLabel: "Pharmacist's message: ",
          quantity: { "1_MONTH": "1 month", "3_MONTHS": "3 months" },
          destination: {
            RESIDENCE: "At home",
            POST_PICK_UP: "Pickup (Canada Post)*",
            PHARMACY_PICK_UP: "Pick-up at Pharmacie du Village",
            PRELIB_PICK_UP: "Pick-up (Prelib)",
          },
          pendingDeliveryDescription:
            "Your delivery status is pending. The delivery will be confirmed by the pharmacist in the week preceding the delivery.",
          confirmedAt: "Delivery confirmed on:",
          rejectedAt: "Delivery rejected by the pharmacist on: ",
          deliveredAt: "Delivery delivered on: ",
          prelibPickUpDisclaimer: `*The delivery date is the date of your next Prelib screening appointment at Prelib. If your appointment is cancelled, your PrEP will be delivered by the alternative method of your preference.`,
          modify: "Modify",
          confirm: "Confirm",
          refuse: "Refuse",
          pickUpAtPrelibOn: "Pick-up during the screening on ",
          pharmacieDuVillage: "Pharmacie du Village",
          prelibPickUpInfo1:
            "If you confirm a delivery but change your mind, you can always cancel it up to 72 hours before the scheduled date.",
          prelibPickUpInfo2:
            "If you refuse a scheduled delivery, you can reactivate it before your next one.",
          der: {
            REJECTED:
              "The date change for this delivery has been rejected, the delivery will remain on the original date.",
            ACCEPTED:
              "The date change for this delivery has been accepted, the delivery will be delivered on the new date.",
          },
          requestedDate: "Requested date: ",
        },
        showMore: "show more",
        showLess: "show less",
        description: {
          "1_MONTH":
            'The "Scheduled delivery (1 month)" profile allows you to benefit from automatic PrEP delivery every month, provided you keep your medical records up to date. This option can give you greater flexibility in managing your PrEP, avoiding oversights and repeated trips.',
          "3_MONTHS":
            'The "Scheduled delivery (3 months)"" profile allows you to benefit from automatic PrEP delivery every 3 months, provided you keep your medical records up to date. This option can give you greater flexibility in managing your PrEP, avoiding oversights and repeated trips.',
          ON_DEMAND:
            'The "On-Demand Delivery" profile allows you to benefit from a PrEP delivery whenever you wish, without using the automated scheduling tool.',
          ON_PAUSE: "",
        },
        onDemand: {
          quantityLabel: "Desired quantity*:",
          dateLabel: "Delivery date:",
          datePlaceholder: "Select a date",
          quantityError: "Please choose a quantity!",
          dateError: "Please choose a delivery date!",
          submitBtnLabel: "Request Delivery",
          quantityOptions: {
            "1_MONTH": "1 month",
            "3_MONTHS": "3 months",
          },
          quantityDisclaimer: "",
        },
        status: {
          REQUEST_IN_PROGRESS: "On request",
          PENDING: "Pending",
          CANCELED: "Canceled",
          CONFIRMED: "Confirmed",
          DELIVERED: "Delivered",
          REJECTED: "Rejected",
        },
        btn: "TODO",
        preferencesCTA: "Preferences",
      },
    },
    deliverySettingsForm: {
      title: "My delivery settings",
      subtitles: [
        "Fill out your delivery preferences below.",
        "You can adjust them later.",
      ],
      date: {
        label: "Delivery date",
        placeholder: "Delivery date selection",
        warning:
          "*You will not be able to select a delivery option for the next 72 hours, while the pharmacist confirms your request.",
      },
      frequency: {
        label: "Delivery frequency",
        placeholder: "Delivery frequency selection",
        options: {
          "1_MONTH": {
            title: "Recurring (every month)",
          },
          "3_MONTHS": {
            title: "Recurring (every 3 months)",
          },
          ON_DEMAND: {
            title: "On demand*",
            details:
              "* Cette option te permet de demander une livraison lorsque tu le souhaites sans utiliser une planification automatisée.",
          },
          ON_PAUSE: {
            title: "Paused*",
            details:
              "* Lorsque tu mets sur pause tes livraisons, ton statut PrEP est toujours actif. Si tu le souhaites, tu peux réactiver tes livraisons via ton profil de préférence.",
          },
        },
      },
      destination: {
        label: "Delivery destination",
        placeholder: "Delivery destination selection",
        options: {
          RESIDENCE: {
            title: "At home",
          },
          POST_PICK_UP: {
            title: "Pickup (Canada Post)*",
            details:
              "* If you select Canada Post pick-up, a delivery notice will be sent to the email address in your file when your package is ready for pick-up at a point of service near you.",
          },
          PHARMACY_PICK_UP: {
            title: "Pick-up at Pharmacie du Village",
          },
        },
      },
      address: {
        label: "Address",
        placeholder: "ex: 1050 Lorem Ipsum",
      },
      city: {
        label: "City",
        placeholder: "ex: Montreal",
      },
      zipCode: {
        label: "Postal Code",
        placeholder: "ex: H3B0G3",
      },
      province: {
        label: "Province",
        value: "QC",
        tooltip: "Delivery available only in Quebec",
      },
      activateDeliveryCTA: "Enable deliveries",
      saveDeliverySettingsCTA: "Save",
      cancelDeliverySettingsCTA: "Cancel",
    },
  },
};
