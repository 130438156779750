import Question, {
  extractQuestionError,
  extractAnswerFromQuestion,
  UserQuestionnaireAnswer,
} from "./question";

export default interface Questionnaire {
  id?: number;
  questions: Question[];
}

export interface QuestionnaireError {
  error: string;
  index: number;
  questionIndex: string;
}

export const shouldDisplayQuestion = (
  { optionalDisplayRef, optionalDisplayAnswers }: Question,
  questionnaire: Questionnaire
): boolean => {
  if (!optionalDisplayRef && !optionalDisplayAnswers) return true;

  //to temporarily hide the prepInterest question until related legal issues have been resolved
  if (optionalDisplayRef === "prepStatus") {
    return false;
  }

  const displayRefs = optionalDisplayRef!.split("|");
  const refQuestions = questionnaire.questions.filter(
    ({ key }) => displayRefs.indexOf(key) > -1
  );

  const displayAnswers = optionalDisplayAnswers!.split("|");

  return refQuestions.some((refQuestion) => {
    if (refQuestion.type === "checkbox") {
      return (
        refQuestion.selectedAnswers?.some(
          ({ rawValue }) => displayAnswers.indexOf(rawValue) > -1
        ) ?? false
      );
    } else {
      return displayAnswers.indexOf(refQuestion.answer?.rawValue) > -1;
    }
  });
};

export const buildQuestionArray = (
  questionnaire: Questionnaire
): Question[] => {
  return questionnaire.questions.filter((question) =>
    shouldDisplayQuestion(question, questionnaire)
  );
};

export const extractQuestionnaireErrors = (
  questionnaire: Questionnaire,
  translations: any
): QuestionnaireError[] => {
  const questions = buildQuestionArray(questionnaire);

  const errors: QuestionnaireError[] = [];
  for (let i = 0; i < questions.length; i++) {
    const error = extractQuestionError(questions[i], translations);
    if (error)
      errors.push({ error, index: i, questionIndex: questions[i].index });
  }
  return errors;
};

export const extractAnswers = (
  questionnaire: Questionnaire
): UserQuestionnaireAnswer[] => {
  let answers: UserQuestionnaireAnswer[] = [];

  const questions = buildQuestionArray(questionnaire);
  for (const question of questions) {
    const answer = extractAnswerFromQuestion(question);

    if (answer && !Array.isArray(answer)) {
      answers = [...answers, answer];
    } else if (answer && Array.isArray(answer)) {
      answers = [...answers, ...answer];
    }
  }

  return answers;
};
