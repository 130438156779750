import useAnalytics from "hooks/analytics";
import { DateTime } from "luxon";
import { MIN_BOOK_APPOINTMENT_HOUR_DELAY } from "models/appointment";
import React, { SetStateAction, useEffect } from "react";
import { UserActionEvent } from "utils/analytics/events";
import { Accordion, Button } from "../../../../components";
import AppointmentDatetimePicker from "../../../../components/appointment-datetime-picker";
import useLanguage from "../../../../hooks/language";
import { Hr } from "../date-selection";
import styles from "./prep.module.css";

interface PrepProps {
  wantsPrep?: boolean;
  setPrepEligibilityDate: React.Dispatch<SetStateAction<string | null>>;
  setWantsPrep: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const Prep: React.FunctionComponent<PrepProps> = ({
  wantsPrep,
  setWantsPrep,
  setPrepEligibilityDate,
}: PrepProps) => {
  const { translations } = useLanguage();
  const t = translations.dateSection;
  const [analytics] = useAnalytics();

  useEffect(() => {
    setPrepEligibilityDate(null);
  }, [wantsPrep]);

  const onWantsPrepChecked = (wantsPrep: boolean): void => {
    if (!wantsPrep) {
      analytics
        ?.cdp()
        ?.trackEvent(UserActionEvent.ScreeningPrepAppointmentDeclined);
    }

    setWantsPrep(wantsPrep);
  };

  return (
    <>
      <Accordion
        header={<h3>{t.prepSubtitle}</h3>}
        defaultOpen={wantsPrep === undefined ? true : wantsPrep}
      >
        <div className={styles.container}>
          <p>{t.prepDescription}</p>
          <div className={styles.CtaRow}>
            <Button onClick={() => setWantsPrep(true)}>
              {t.makePrepAppointmentButtonText}
            </Button>
            <div className={styles.checkboxWrapper}>
              <input
                type="checkbox"
                checked={wantsPrep === undefined ? false : !wantsPrep}
                onChange={(e) => onWantsPrepChecked(!e.target.checked)}
              />
              <label>
                <u>{t.abortPrepCheckboxText}</u>
              </label>
            </div>
          </div>
          <div className={styles.consent}>{t.prepConsent}</div>
        </div>
      </Accordion>
      <Hr />
      {wantsPrep && (
        <>
          <AppointmentDatetimePicker
            isPrepEligiblityAppointment
            onSelectDate={setPrepEligibilityDate}
            startDate={DateTime.now()
              .plus({ hours: MIN_BOOK_APPOINTMENT_HOUR_DELAY })
              .toISO()}
          />
          <Hr />
        </>
      )}
    </>
  );
};

export default Prep;
