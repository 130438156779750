import useAuthToken from "hooks/auth-token";
import useFeatureManager from "hooks/featureManager";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlag } from "utils/feature-management/amplitude";
import { Footer, Header } from "../../components";
import ROUTES from "../../utils/routes";
import LoginForm from "./login-form";
import styles from "./login.module.css";
import SignupForm from "./signup-form";

const LoginPage: React.FunctionComponent = () => {
  const { push } = useHistory();
  const [authToken] = useAuthToken();

  const [featureManager] = useFeatureManager();
  // States

  const [loginMode, setLoginMode] = useState(true);

  useEffect(() => {
    if (authToken) {
      push(ROUTES.HOME);
    }
  }, []);

  const onSetSignUpMode = (isLogin: boolean): void => {
    if (isLogin) {
      setLoginMode(true);

      return;
    }

    if (
      featureManager?.isFeatureFlagEnabled(
        "how-did-you-hear-about-us",
        FeatureFlag.On
      )
    ) {
      push(ROUTES.SIGN_UP);
    } else {
      setLoginMode(false);
    }
  };

  // Rendering

  return (
    <div className="page">
      <Header />

      <div className={`${styles.container} preauth-container`}>
        {!loginMode && (
          <SignupForm setLoginMode={() => onSetSignUpMode(true)} />
        )}
        {loginMode && (
          <LoginForm setSignupMode={() => onSetSignUpMode(false)} />
        )}
      </div>

      <Footer />
    </div>
  );
};

export default LoginPage;
