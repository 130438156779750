import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { EMAIL_REGEX } from "../../utils/validation";
import ENDPOINTS from "../../utils/endpoints";
import useAuthToken from "../../hooks/auth-token";
import useLanguage from "../../hooks/language";
import { Button, Field } from "../../components";
import ROUTES, {
  EMAIL_CONFIRMATION_BASE,
  PASSWORD_RESET_BASE,
} from "../../utils/routes";

import loginIconSrc from "../../assets/images/login-icon.svg";

import styles from "./login.module.css";
import { getCookie } from "../../utils/local-storage";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

interface LoginFormProps {
  setSignupMode: () => void;
}

const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  setSignupMode,
}: LoginFormProps) => {
  const { push } = useHistory();
  const setAuthToken = useAuthToken()[1];
  const { translations } = useLanguage();
  const t = translations.login;
  const [analytics] = useAnalytics();

  // States

  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [rememberMeToken, setRememberMeToken] = useState<string | null>(null);

  // Effect
  useEffect(() => {
    setRememberMeToken(getCookie("rememberMeToken"));
  }, []);
  // Errors

  const emailError = !EMAIL_REGEX.test(email) ? t.invalidEmailError : null;
  const passwordError = password.length === 0 ? t.requiredFieldError : null;
  const hasError = emailError !== null || passwordError !== null;
  const disabled =
    email.length === 0 || password.length === 0 || (submitted && hasError);

  // Handlers

  const submit = () => {
    setSubmitted(true);

    analytics?.cdp()?.trackEvent(UserActionEvent.LoginInitiated);
    analytics?.web()?.trackEvent(UserActionEvent.LoginInitiated);

    if (hasError) return;

    login();
  };

  // Network

  const login = async () => {
    setLoading(true);

    try {
      const payload = {
        email,
        password,
        rememberMeToken,
        passwordResetRoute: PASSWORD_RESET_BASE,
        emailConfirmationRoute: EMAIL_CONFIRMATION_BASE,
      };
      const { data } = await Axios.post(ENDPOINTS.LOGIN, payload);
      const { authToken, tfaSettingsToken, tfaToken, sentTo, sentBy } = data;

      if (tfaSettingsToken && authToken) {
        setAuthToken(authToken);
        push(`${ROUTES.TFA_SETTINGS}?tfaSettingsToken=${tfaSettingsToken}`);
      } else if (authToken) {
        setAuthToken(authToken);
        push(ROUTES.HOME);
      } else if (tfaToken) {
        push(
          `${ROUTES.TFA_INPUT}?tfaToken=${tfaToken}&sentTo=${sentTo}&sentBy=${sentBy}`
        );
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div>
      <div className={styles.imgHolder}>
        <img src={loginIconSrc} />
      </div>
      <h2>{t.loginHeader}</h2>

      <div className="input-holder">
        <label>{t.emailLabel}</label>
        <Field
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          onPressEnter={submit}
          error={submitted ? emailError : null}
        />
      </div>
      <div className="input-holder">
        <label>{t.passwordLabel}</label>
        <Field
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          onPressEnter={submit}
          error={submitted ? passwordError : null}
        />
      </div>

      <a onClick={setSignupMode}>{t.createAccountLink}</a>
      <a onClick={() => push(ROUTES.PASSWORD_FORGOTTEN)}>
        {t.forgottenPasswordLink}
      </a>

      <Button disabled={disabled} loading={loading} onClick={submit}>
        {t.loginButtonText}
      </Button>
    </div>
  );
};

export default LoginForm;
