import React, { useState } from "react";
import Axios from "axios";
import { NotificationManager } from "react-notifications";
import useLanguage from "../../hooks/language";
import { Button } from "../../components";
import PhoneAppointment from "../../models/phone-appointment";
import ENDPOINTS from "../../utils/endpoints";
import useAnalytics from "hooks/analytics";
import { UserActionEvent } from "utils/analytics/events";

interface RejectPhoneAppointmentModalProps {
  onClose?: () => void;
  onConfirm?: (appointment: PhoneAppointment) => void;
  appointment?: PhoneAppointment;
}

const RejectPhoneAppointmentModal: React.FunctionComponent<RejectPhoneAppointmentModalProps> = ({
  onClose,
  onConfirm,
  appointment,
}: RejectPhoneAppointmentModalProps) => {
  const { translations } = useLanguage();
  const t = translations.appointmentsSection;
  const [analytics] = useAnalytics();

  // States

  const [loading, setLoading] = useState(false);

  // Network

  const rejectAppointment = async () => {
    if (!appointment) return;

    setLoading(true);

    try {
      await Axios.put(ENDPOINTS.REJECT_PHONE_APPOINTMENT(appointment.id));

      if (onConfirm) {
        onConfirm(appointment);
      }

      analytics
        ?.cdp()
        ?.trackEvent(UserActionEvent.ScreeningPrepAppointmentCancelled);

      NotificationManager.success(t.cancellationModal.phoneAppointmentRejected);
    } finally {
      setLoading(false);
    }
  };

  // Rendering

  return (
    <div>
      {appointment?.type === "PREP_ELIGIBILITY" && (
        <p>{t.cancellationConfirmModal.prepEligiblity}</p>
      )}
      <div className="dual-button-holder">
        <Button onClick={onClose} disabled={loading}>
          {t.cancellationModal.exitButtonText}
        </Button>
        <Button onClick={rejectAppointment} type="secondary" loading={loading}>
          {t.cancellationModal.confirmButtonText}
        </Button>
      </div>
    </div>
  );
};

export default RejectPhoneAppointmentModal;
