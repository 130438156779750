import Axios from "axios";
import { useLastAppointment } from "context/last-appointment";
import { isCompleted, isScheduled } from "models/appointment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Loader } from "react-feather";
import { NotificationManager } from "react-notifications";
import { Redirect, useHistory } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import { UserActionEvent } from "utils/analytics/events";
import { Breadcrums, Header, Modal } from "../../../components";
import useAnalytics from "../../../hooks/analytics";
import useAuthUser from "../../../hooks/auth-user";
import useLanguage from "../../../hooks/language";
import {
  ConsentModal,
  ExpiredQuestionnaireModal,
  MedicalRecommendationModal,
} from "../../../modals";
import SuggestedMedicalTest from "../../../models/suggested-medical-test";
import User, {
  expiredPendingQuestionnaire,
  hasCompletedQuestionnaireWithoutScheduling,
  hasFollowUpPhoneAppointmentToComplete,
  hasPaidLastQuestionnaire,
  hasPrepEligibilityPA,
  hasPrepInterest,
  isPrepEligible,
  upcomingScreeningAppointment,
} from "../../../models/user";
import ENDPOINTS from "../../../utils/endpoints";
import ROUTES from "../../../utils/routes";
import DateSelection from "./date-selection";
import MedicalEvaluation from "./medical-evaluation";
import Payment from "./payment";
import PersonalInfo from "./personal-info";
import Recommendations from "./recommendations";
import styles from "./screening.module.css";

export interface ScreeningPage {
  content: ReactElement;
  title: string;
}

const getDefaultIndex = (
  user: User | null,
  fromUserQuestionnaireId: number | undefined = undefined
) => {
  if (
    user &&
    hasCompletedQuestionnaireWithoutScheduling(user) &&
    !expiredPendingQuestionnaire(user)
  ) {
    if (
      user.hasToPay &&
      hasPaidLastQuestionnaire(user) &&
      !fromUserQuestionnaireId
    ) {
      return 3;
    }
    return 2;
  }
  return 0;
};

const ScreeningPage: React.FunctionComponent = () => {
  const { push } = useHistory();
  const { translations } = useLanguage();
  const [authUser, setAuthUser] = useAuthUser();
  const t = translations.appointmentsSection;
  const tTitles = translations.sectionSelection.makeScreeningSection;
  const tModal = translations.questionnaireSection.modal;
  const tExpired = translations.expiredSection;
  const { hasToPayLastMissedAppointment } = useLastAppointment();
  const [analytics] = useAnalytics();

  const upcomingAppointment = authUser
    ? upcomingScreeningAppointment(authUser)
    : undefined;

  // Redirect user if he has a follow up phone appointment to complete or need to pay last missed appointment
  if (
    authUser &&
    (hasFollowUpPhoneAppointmentToComplete(authUser) ||
      hasToPayLastMissedAppointment)
  ) {
    return <Redirect to={ROUTES.APPOINTMENTS} />;
  }

  // Refs

  const containerRef = useRef<HTMLDivElement | null>(null);

  // States

  const [consentModalVisible, setConsentModalVisible] = useState(false);
  const [wantsPrep, setWantsPrep] = useState<boolean | undefined>();

  // Not to clean but otherwise the modal is shown twice and I don't like it much
  const [recommendationModalVisible, setRecommendationModalVisible] = useState(
    false
  );
  const [
    expiredQuestionnaireModalVisible,
    setExpiredQuestionnaireModalVisible,
  ] = useState(false);
  // Not to clean but otherwise the modal is shown twice and I don't like it much
  const [
    expiredQuestionnaireModalHasBeenShown,
    setExpiredQuestionnaireModalHasBeenShown,
  ] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(getDefaultIndex(authUser));
  const [minIndex, setMinIndex] = useState(getDefaultIndex(authUser));
  const [maxIndex, setMaxIndex] = useState(getDefaultIndex(authUser));
  const [fromUserQuestionnaireId, setFromUserQuestionnaireId] = useState<
    number | undefined
  >();
  const [selectedMedicalTests, setSelectedMedicalTests] = useState<
    SuggestedMedicalTest[] | undefined
  >();

  const [isLoading, setIsLoading] = useState(false);

  // Effects

  useEffect(() => {
    setCurrentIndex(getDefaultIndex(authUser, fromUserQuestionnaireId));
    setMinIndex(getDefaultIndex(authUser, fromUserQuestionnaireId));
    setMaxIndex(getDefaultIndex(authUser, fromUserQuestionnaireId));

    if (
      authUser &&
      expiredPendingQuestionnaire(authUser) &&
      !expiredQuestionnaireModalHasBeenShown
    ) {
      setExpiredQuestionnaireModalVisible(true);
      setExpiredQuestionnaireModalHasBeenShown(true);
    }
  }, [authUser]);

  useEffect(() => {
    if (expiredQuestionnaireModalVisible)
      setExpiredQuestionnaireModalHasBeenShown(true);
  }, [expiredQuestionnaireModalVisible]);

  useEffect(() => {
    if (!containerRef.current) return;
    scroll.scrollTo(containerRef.current.offsetTop - 100, { duration: 500 });
  }, [currentIndex]);

  // Handlers

  const onConsent = () => {
    setConsentModalVisible(false);
    setRecommendationModalVisible(true);
    setCurrentIndex(1);
    setMaxIndex(1);

    analytics
      ?.cdp()
      ?.trackEvent(UserActionEvent.ScreeningPrivacyConsentAccepted);
  };

  const onDeclineConsent = () => {
    setConsentModalVisible(false);
    analytics
      ?.cdp()
      ?.trackEvent(UserActionEvent.ScreeningPrivacyConsentDeclined);
  };

  const onCompleteQuestionnaire = () => {
    setCurrentIndex(2);
    setMinIndex(2);
    setMaxIndex(2);
  };

  const onCompletePayment = () => {
    setCurrentIndex(3);
    setMinIndex(3);
    setMaxIndex(3);
  };

  const onRestartQuestionnaireClick = () => {
    setFromUserQuestionnaireId(authUser?.lastUserQuestionnaireId);
    setCurrentIndex(0);
    setMinIndex(0);
    setMaxIndex(0);

    const user = {
      ...authUser,
      lastUserQuestionnaire: undefined,
    };
    setAuthUser(user as User);
  };

  const onSelectMedicalTests = (tests: SuggestedMedicalTest[]) => {
    setSelectedMedicalTests(tests);
    setCurrentIndex(currentIndex + 1);
    setMaxIndex(maxIndex + 1);
  };

  // Helper const

  const canChoosePrepDate = authUser
    ? hasPrepInterest(authUser) &&
      !isPrepEligible(authUser) &&
      !hasPrepEligibilityPA(authUser)
    : false;

  // Network

  const bookAppointment = async (
    datetime: string,
    clinicId: number,
    prepDate: string | null
  ) => {
    if (!authUser || !selectedMedicalTests) return;

    setIsLoading(true);

    const { lastUserQuestionnaireId: userQuestionnaireId } = authUser;
    const chosenMedicalTests = selectedMedicalTests.map(({ medicalTest }) => ({
      medicalTestId: medicalTest.id,
    }));

    const isPrepRefused = canChoosePrepDate ? !wantsPrep : undefined;

    const body = {
      userQuestionnaireId,
      chosenMedicalTests,
      datetime,
      clinicId,
      ...(prepDate && { prepDate }),
      isPrepRefused,
    };

    try {
      await Axios.post(ENDPOINTS.APPOINTMENTS, body);
      const { data } = await Axios.get(ENDPOINTS.ME);
      setAuthUser(data);
      NotificationManager.success(tTitles.appointmentCreated);

      if (!isPrepRefused) {
        analytics
          ?.cdp()
          ?.trackEvent(UserActionEvent.ScreeningPrepAppointmentBooked);
      }

      const eventProperties = {
        appointmentCount:
          (data as User).appointments?.filter(
            (appointment) =>
              isCompleted(appointment) || isScheduled(appointment)
          )?.length ?? 0,
      };

      analytics
        ?.cdp()
        ?.trackEvent(
          UserActionEvent.ScreeningAppoinmentBooked,
          eventProperties
        );
      analytics
        ?.web()
        ?.trackEvent(
          UserActionEvent.ScreeningAppoinmentBooked,
          eventProperties
        );

      push(ROUTES.HOME);
    } catch {
      setIsLoading(false);
    }
  };

  // Rendering

  const isLoadingContent = authUser === null || authUser === undefined;

  const pages: ScreeningPage[] = [
    {
      title: tTitles.information,
      content: (
        <div className={styles.page} key={0}>
          <PersonalInfo onSubmit={() => setConsentModalVisible(true)} />
        </div>
      ),
    },
    {
      title: tTitles.medicalEvaluation,
      content: (
        <div className={styles.page} key={1}>
          <MedicalEvaluation
            onSubmit={onCompleteQuestionnaire}
            fromUserQuestionnaireId={fromUserQuestionnaireId}
          />
        </div>
      ),
    },
  ];

  const expiredQuestionnaire =
    authUser && expiredPendingQuestionnaire(authUser);
  const hasAlreadyPaid =
    expiredQuestionnaire && hasPaidLastQuestionnaire(authUser!);
  const hasToPay =
    authUser && authUser.hasToPay && !upcomingAppointment && !hasAlreadyPaid;

  if (hasToPay && !fromUserQuestionnaireId) {
    pages.push({
      title: tTitles.payment,
      content: (
        <div className={styles.page} key={2}>
          <Payment onCompletePayment={onCompletePayment} />
        </div>
      ),
    });
  }

  pages.push({
    title: tTitles.recommendations,
    content: (
      <div className={styles.page} key={3}>
        <Recommendations
          upcomingAppointment={upcomingAppointment}
          selectedMedicalTests={selectedMedicalTests}
          onRestartQuestionnaireClick={onRestartQuestionnaireClick}
          onSelectMedicalTests={onSelectMedicalTests}
        />
      </div>
    ),
  });

  if (!upcomingAppointment) {
    pages.push({
      title: tTitles.date,
      content: (
        <div className={styles.page} key={4}>
          <DateSelection
            onSubmit={bookAppointment}
            loading={isLoading}
            canChoosePrepDate={canChoosePrepDate}
            wantsPrep={wantsPrep}
            setWantsPrep={setWantsPrep}
          />
        </div>
      ),
    });
  }

  const loadingElement = (
    <p className={styles.loader}>
      <Loader className="spinorama" />
      {t.loadingInfo}
    </p>
  );

  // Fix smoll crash for the small laps of time after user created appointment so there is an upcoming appointment so less pages
  let contentIndex = currentIndex;
  if (currentIndex >= pages.length) contentIndex = pages.length - 1;

  return (
    <div ref={containerRef}>
      {/* Modals */}
      <Modal
        title={tModal.consentHeader}
        visible={consentModalVisible}
        onVisibilityChange={setConsentModalVisible}
        className={styles.consentModal}
      >
        <ConsentModal onClose={onDeclineConsent} onConsent={onConsent} />
      </Modal>
      <Modal
        title={tModal.recommendationHeader}
        visible={recommendationModalVisible}
        onVisibilityChange={setRecommendationModalVisible}
      >
        <MedicalRecommendationModal
          onClose={() => setRecommendationModalVisible(false)}
        />
      </Modal>
      <Modal
        title={tExpired.header}
        visible={expiredQuestionnaireModalVisible}
        onVisibilityChange={setExpiredQuestionnaireModalVisible}
      >
        <ExpiredQuestionnaireModal
          onClose={() => setExpiredQuestionnaireModalVisible(false)}
        />
      </Modal>

      <Header />

      {/* Page content */}

      {isLoadingContent && <div className="container">{loadingElement}</div>}
      {!isLoadingContent && (
        <Breadcrums
          crumTitles={pages.map(({ title }) => title)}
          currentIndex={currentIndex}
          maxIndex={maxIndex}
          minIndex={minIndex}
          onClickIndex={setCurrentIndex}
        />
      )}

      {!isLoadingContent && (
        <div className={styles.pageHolder}>{pages[contentIndex].content}</div>
      )}
    </div>
  );
};

export default ScreeningPage;
