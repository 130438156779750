import Plausible, { EventOptions, PlausibleOptions } from "plausible-tracker";
import { IWebAnalyticsService } from "..";
import { UserActionEvent } from "../events";

type Cleanup = () => void;

export interface IPlausibleConfig {
  domainURL: string;
  apiHostURL: string;
  trackLocalhost: boolean;
}
export class PlausibleAnalytics implements IWebAnalyticsService {
  // NOTE: Plausisble's SDK does not export a type containing its functionality. Instead it provides a function
  // that exposes the following tracking functions that we see below
  private _plausible: {
    readonly trackEvent: (
      eventName: string,
      options?: EventOptions,
      eventData?: PlausibleOptions
    ) => void;
    readonly trackPageview: (
      eventData?: PlausibleOptions,
      options?: EventOptions
    ) => void;
    readonly enableAutoPageviews: () => Cleanup;
    readonly enableAutoOutboundTracking: (
      targetNode?: Node & ParentNode,
      observerInit?: MutationObserverInit
    ) => Cleanup;
  };

  /**
   * @constructor
   * @param {string} apiHostURL - A string containing the Plausible URL which collects data sent from this
   *                              analytics service
   * @param {string} domainURL - A string containing the Prelib's domain identifier defined within Plausible.
   *                             Note that this varies between multiple environments of this application
   * @param {boolean} trackLocalhost - A boolean that, is set to true, will track events in a local enviroment
   */
  public constructor(
    apiHostURL: string,
    domainURL: string,
    trackLocalhost: boolean
  ) {
    if (!apiHostURL) {
      throw new Error("hostURL");
    }

    if (!domainURL) {
      throw new Error("domainURL");
    }

    this._plausible = Plausible({
      domain: domainURL,
      apiHost: apiHostURL,
      trackLocalhost: trackLocalhost,
    });
  }

  /**
   * Sends events to plausible server defined in apiHostURL to the a Prelib domain defined in domainURL
   * @param {UserActionEvent} eventName - An event name defined as a {@link UserActionEvent}
   * @param {T} eventProperties - A generic object with fields containing event information
   */
  public trackEvent<T>(eventName: UserActionEvent, eventProperties?: T): void {
    this._plausible.trackEvent(eventName, {
      props: { ...eventProperties },
    });
  }

  public pageView<T>(eventProperties?: T): void {
    this._plausible.trackPageview(eventProperties);
  }
}
