import { useContext } from "react";
import AppContext from "../context/app";
import { IFeatureManager } from "../utils/feature-management";

type FeatureManagerHook = [
  IFeatureManager | null,
  (service: IFeatureManager) => void
];

const useFeatureManager = (): FeatureManagerHook => {
  const { featureManager, setFeatureManager } = useContext(AppContext);

  return [featureManager, setFeatureManager];
};

export default useFeatureManager;
