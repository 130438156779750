export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
export const LOWERCASE_REGEX = /[a-z]/;
export const UPPERCASE_REGEX = /[A-Z]/;
export const DIGIT_REGEX = /\d/;
export const SPECIAL_CHAR_REGEX = /\W|_/;
export const RAMQ_REGEX = /^[A-Z]{4}[0-9]{8}$/im;
export const HOTMAIL_REGEX = /[a-zA-Z0-9]{0,}([.]?[a-zA-Z0-9]{1,})[@](hotmail)[.][a-z]{0,}/;

export const normalizeString = (str: string): string =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

const singleDigitFormatter = (val: number) => {
  return val < 10 ? `0${val}` : `${val}`;
};

const isValidDate = (value: string, day: number, month: number) => {
  const timestamp = Date.parse(value);
  const date = new Date(value);

  return (
    !isNaN(timestamp) && date.getDate() === day && date.getMonth() + 1 === month
  );
};

const getFullDateYear = (value: string) => {
  const now = new Date();
  const currentYear = now.getFullYear();

  const yearP1 = currentYear % 100;
  const yearP2 = Math.floor(currentYear / 100);
  const yearPrefix = Number(value) > yearP1 ? yearP2 - 1 : yearP2;

  return `${yearPrefix}${value}`;
};

export const isValidRAMQ = (value: string): boolean => {
  let formattedValue = value;

  // Why 14? Because we might want to also parse ramqValue with space, ex: JKEL 9206 1822
  if (formattedValue.length === 14) {
    formattedValue = formattedValue.replace(/ /g, "");
  }

  const regex = /^[a-z]{4}[0-9]{8}$/i;
  if (!regex.test(formattedValue)) return false;

  const extractedYear = formattedValue.slice(4, 6);
  let extractedMonth = Number(formattedValue.slice(6, 8));
  if (extractedMonth > 50) extractedMonth = extractedMonth - 50; // For girls, the month part of the ramq number is in range [51, 62]
  const extractedDay = formattedValue.slice(8, 10);

  const dateString = `${getFullDateYear(extractedYear)}-${singleDigitFormatter(
    extractedMonth
  )}-${extractedDay}T12:00:00`;

  return isValidDate(dateString, Number(extractedDay), Number(extractedMonth));
};
