export const EMAIL_CONFIRMATION_BASE = "/email-confirmation";
export const PASSWORD_RESET_BASE = "/password-reset";

export default {
  LOGIN: "/login",
  EMAIL_CONFIRMATION: `${EMAIL_CONFIRMATION_BASE}/:token`,
  PASSWORD_FORGOTTEN: "/password-forgotten",
  PASSWORD_RESET: `${PASSWORD_RESET_BASE}/:token`,
  UNSUBSCRIBE: "/unsubscribe",
  HOME: "/home",
  TFA_INPUT: "/tfa-input",
  TFA_SETTINGS: "/tfa-settings",
  PROFILE: "/profile",
  SCREENING: "/home/screening",
  APPOINTMENTS: "/home/appointments",
  PREP: "/home/prep",
  SIGN_UP: "/signup",
};
